import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import PropTypes from 'prop-types';
import LazyLoad from 'vanilla-lazyload';
import Head from '../Head';
import Header from '../Header';
import Footer from '../Footer';
import Scripts from '../Scripts';
import Canvas from '../Canvas';
import getGuid from '../../../utils/guid';

// Grab UTM codes from the url and store them in the session so they can be utilized later for tracking purposes
import '../../../assets/js/session/utm';

export const PageComponent = ({
  // CONFIG VARS
  config,
  dynamicConfig,
  // PAGE COMPONENT
  children,
  mainAttributes,
  // HEAD PROPS
  canonical,
  dynatraceScriptUrl,
  pageDescription,
  headComponent,
  metaRobotsNoIndexNoFollow,
  siteTitleFirst,
  pageTitle,
  useReCaptcha,
  useHeap,
  // HEADER PROPS
  breadcrumbsNavigationConfig,
  customHeader,
  headerIsOverlayed,
  headerLogoEyebrow,
  headerLogoEyebrowLarge,
  headerWithoutBorder,
  hideHeader,
  showInformationalBanner,
  skipNavAside,
  skipNavFooter,
  useAkzidenz,
  // SCRIPTS PROPS
  showGooglePlaces,
  // FOOTER PROPS
  appendFooter,
  customDisclaimers,
  customFooter,
  disableDisclaimerTopBorder,
  disclaimerFootnotes,
  footerVariant,
  isAmp,
  legalCopy,
  stateFilingComplianceNumber,
}) => {
  const theme = useContext(ThemeContext);
  const mediumUp = theme.mediumUp();

  const [isMediumUp, setIsMediumUp] = useState(mediumUp);

  useEffect(() => {
    if (mediumUp !== isMediumUp) {
      setIsMediumUp(mediumUp);
    }
  }, [mediumUp]);

  useEffect(() => {
    // Lazy load images
    // eslint-disable-next-line no-unused-vars
    const lazyload = new LazyLoad({ elements_selector: '.lazy' });
    // Generate a pre client id to usage in adobe and lead records (tie together/back)
    const preClientId = getGuid();
    sessionStorage.setItem('pre-client-id', preClientId);
    window.adobeDataLayer = window.adobeDataLayer || [];

    window.adobeDataLayer.push({
      'user-details': { ids: { 'pre-client-id': preClientId } },
      page: { 'load-path': window.location.pathname.replace(/\/$/, '').toLowerCase() },
    });
    // Generate FB event ID to be sent along with FB conversion pixel events
    const fbEventId = getGuid();
    sessionStorage.setItem('fb-event-id', fbEventId);
    XMLHttpRequest.prototype.open = (function (open) {
      // eslint-disable-next-line no-unused-vars
      return function (method, url, async) {
        // eslint-disable-next-line prefer-rest-params
        open.apply(this, arguments);
        if (url.indexOf('/nmx-api-proxy/nmx/') > 0) {
          this.setRequestHeader('X-Pre-Client-Id', preClientId);
        }
        if (url.indexOf('/nmx-api-proxy/nmx/ms-form-proxy/lead') > 0) {
          this.setRequestHeader('X-Fb-Event-Id', fbEventId);
          this.setRequestHeader('X-Prelead-Contact-Reference-Id', sessionStorage.getItem('prelead-contact-reference-id'));
        }
      };
    }(XMLHttpRequest.prototype.open));
  }, []);

  const preleadContactReferenceId = Location.getQueryParam('preleadContactReferenceId');
  if (preleadContactReferenceId) {
    sessionStorage.setItem('prelead-contact-reference-id', preleadContactReferenceId);
  }

  return (
    <>
      {/* Head properties added to the head tag with Helmet */}
      <Head
        config={config}
        Container={headComponent}
        dynatraceScriptUrl={dynatraceScriptUrl}
        metaRobotsNoIndexNoFollow={metaRobotsNoIndexNoFollow}
        siteTitleFirst={siteTitleFirst}
        pageTitle={pageTitle}
        pageDescription={pageDescription}
        canonical={canonical}
        useReCaptcha={useReCaptcha}
        useHeap={useHeap}
        useAkzidenz={useAkzidenz}
      />
      {/* Canvas component, which sets the max canvas size, and allows for gutters on large devices */}
      <Canvas>
        {/* Header and nav for the page */}
        {customHeader || <Header
          breadcrumbsNavigationConfig={breadcrumbsNavigationConfig}
          config={config}
          dynamicConfig={dynamicConfig}
          hideHeader={hideHeader}
          headerIsOverlayed={headerIsOverlayed}
          headerLogoEyebrow={headerLogoEyebrow}
          headerLogoEyebrowLarge={headerLogoEyebrowLarge}
          headerWithoutBorder={headerWithoutBorder}
          showInformationalBanner={showInformationalBanner}
          skipNavAside={skipNavAside}
          skipNavFooter={skipNavFooter}
        />
        }

        {/* Main document where page components are pased down to - everything specific to the page  */}
        <main
          id='main'
          role='main'
          className='nmx-base'
          style={{
            WebkitBoxSizing: 'border-box',
            boxSizing: 'border-box',
          }}
          {...mainAttributes}>
          {children}
        </main>

        {/* Footer and properties added for the page */}
        <Footer
          appendFooter={appendFooter}
          config={config}
          customDisclaimers={customDisclaimers}
          customFooter={customFooter}
          disableDisclaimerTopBorder={disableDisclaimerTopBorder}
          disclaimerFootnotes={disclaimerFootnotes}
          footerVariant={footerVariant}
          isAmp={isAmp}
          legalCopy={legalCopy}
          stateFilingComplianceNumber={stateFilingComplianceNumber}
        />
      </Canvas>
      {/* Page scripts */}
      <Scripts
        config={config}
        showGooglePlaces={showGooglePlaces}
      />
    </>
  );
};

PageComponent.propTypes = {
  /** Env vars used for varying components */
  config: PropTypes.shape({
    basePath: PropTypes.string.isRequired,
    nmxTemplateVersion: PropTypes.string.isRequired,
    adobeLaunchScript: PropTypes.string.isRequired,
    heapAppId: PropTypes.string.isRequired,
    public: PropTypes.shape({
      googlePlacesApiKey: PropTypes.string,
      googleAnalyticsTrackingId: PropTypes.string,
      recaptchaV3: PropTypes.string,
    }).isRequired,
    services: PropTypes.shape({
      loginPageUrl: PropTypes.string.isRequired,
      loginPageUrlBase: PropTypes.string.isRequired,
      userLogoutUrls: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  dynamicConfig: PropTypes.object,

  /** Data Props */
  fwmData: PropTypes.shape({ useFwmNav: PropTypes.bool.isRequired }),

  /** PAGE - Component to render inside the page - everything other than header, footer, scripts, etc */
  children: PropTypes.any.isRequired,
  /** optional array of attributes to add to <main /> element - i.e. used to add itemScope itemType='https://schema.org/FAQPage' in some cases */
  mainAttributes: PropTypes.object,

  /** HEAD: canonical */
  canonical: PropTypes.string.isRequired,
  /** HEAD: dynatraceScriptUrl passed in from each respective app, will be different for each instance (INT, QA, and Prod) */
  dynatraceScriptUrl: PropTypes.string,
  /** HEAD: container for head component. Replace with react-helmet or react-scripts helmet if not wanting default <head /> */
  headComponent: PropTypes.any,
  /** HEAD: Do not index page with search engines */
  metaRobotsNoIndexNoFollow: PropTypes.bool,
  /** HEAD: Page Description to be placed in head description meta tag */
  pageDescription: PropTypes.string.isRequired,
  /** HEAD: Page Title to be placed in head title meta tag */
  pageTitle: PropTypes.string.isRequired,
  /** HEAD: Should title meta tag show site title first? */
  siteTitleFirst: PropTypes.bool,
  /** HEAD: Do we want to load heap on the page? default is true, we usually want to load this */
  useHeap: PropTypes.bool,
  /** HEAD: Does this page use recaptcha? If true will place script on page */
  useReCaptcha: PropTypes.bool,

  /** HEADER: breadcrumb navigation config - will display if !null */
  breadcrumbsNavigationConfig: PropTypes.array,
  /** HEADER: customHeader will override the  */
  customHeader: PropTypes.node,
  /** HEADER: Full primary nav with gradient overlay on top of body content. Coupled w/ hideHeader shows white logo overlayed on top of dark body */
  headerIsOverlayed: PropTypes.bool,
  /** HEADER: Will enable logo-only block header above body content */
  headerLogoEyebrow: PropTypes.bool,
  /** HEADER: Same as headerLogoEyebrow but larger */
  headerLogoEyebrowLarge: PropTypes.bool,
  /** HEADER: Will remove bottom header border logo-only or full nav */
  headerWithoutBorder: PropTypes.bool,
  /** HEADER: Will enable logo-only header and remove any primary navigation including login */
  hideHeader: PropTypes.bool,
  /** HEADER: showInformationalBanner */
  showInformationalBanner: PropTypes.bool,
  /** HEADER: skipNavAside */
  skipNavAside: PropTypes.bool,
  /** HEADER: skipNavFooter */
  skipNavFooter: PropTypes.bool,

  /** FOOTER: TODO - STILL BEING USED? Append additional content to footer. */
  appendFooter: PropTypes.any,
  /** FOOTER: Provide custom disclaimers to left of stock disclaimer */
  customDisclaimers: PropTypes.any,
  /** FOOTER: CustomFooter overrides the footer! */
  customFooter: PropTypes.shape({
    /** Required customFooter content */
    footerContent: PropTypes.node.isRequired,
    /** Required field to satisfy HTML5 accessibility and SEO requirements */
    footerHeadingAssistiveText: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]).isRequired,
  }),
  /** FOOTER: optional boolean to remove top border (when page last section has a background color other than white) */
  disableDisclaimerTopBorder: PropTypes.bool,
  /** FOOTER: Provide custom disclaimer footnotes to left of stock disclaimer */
  disclaimerFootnotes: PropTypes.any,
  /** FOOTER: optional footer variant prop to select which specific (and different) footer to render */
  footerVariant: PropTypes.oneOf(['nmCom', 'sare', 'custom']),
  /** FOOTER: Is this an amp page? */
  isAmp: PropTypes.bool,
  /** FOOTER: TODO - STILL BEING USED? Use legal copy. */
  legalCopy: PropTypes.any,
  /** optional state filing compliance number for pages that have to legally display one */
  stateFilingComplianceNumber: PropTypes.any,

  /** SCRIPTS: Show and add google places script */
  showGooglePlaces: PropTypes.bool,
};

PageComponent.defaultProps = {
  metaRobotsNoIndexNoFollow: false,
  siteTitleFirst: false,

  breadcrumbsNavigationConfig: null,
  headerIsOverlayed: false,
  headerWithoutBorder: false,
  hideHeader: false,
  showInformationalBanner: true,
  skipNavAside: false,
  skipNavFooter: false,

  customDisclaimers: [],
  disableDisclaimerTopBorder: false,
  disclaimerFootnotes: [],
  footerVariant: 'nmCom',
  isAmp: false,
  showGooglePlaces: false,
  useReCaptcha: false,
  useHeap: true,

  fwmData: { useFwmNav: false },
};

export default PageComponent;
