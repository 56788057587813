import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// styles
import {
  TableStyled,
  TdStyledTableBodyCell,
  TdStyledTableFootCell,
  TdStyledTableHeadCell,
  ThStyledTableBodyHeader,
  ThStyledTableFootHeader,
  ThStyledTableHeadHeader,
  TrStyledTableBodyRow,
  TrStyledTableHeadRow,
} from './styles';

// getters
export const getColScope = (colScope) => {
  if (colScope === 'col' || colScope === 'colgroup') {
    return { scope: colScope };
  }
  return {};
};

export const getRowScope = (rowScope) => {
  if (rowScope === 'row' || rowScope === 'rowgroup') {
    return { scope: rowScope };
  }
  return {};
};

export const getTableHeadCol = (col, colIndex, removeTableBorder, rowIndex, variant) => {
  const TableHeadCellComponent = !col.children || (col.isHeader !== undefined && !col.isHeader)
    ? TdStyledTableHeadCell
    : ThStyledTableHeadHeader;

  return (
    <TableHeadCellComponent
      {...(col.className && { className: col.className })}
      {...(col.colSpan && { colSpan: col.colSpan })}
      {...(col.id && { id: col.id })}
      key={`head-row${rowIndex + 1}-col${colIndex + 1}`}
      $width={col.width}
      removeTableBorder={removeTableBorder}
      {...getColScope(col.colScope)}
      variant={variant}>
      {col.children}
    </TableHeadCellComponent>
  );
};

export const getTableHeadRow = (removeTableBorder, row, rowIndex, variant) => (
  <TrStyledTableHeadRow
    key={`head-row${rowIndex + 1}`}
    variant={variant}>
    {row.cols?.map((col, colIndex) => getTableHeadCol(col, colIndex, removeTableBorder, rowIndex, variant))}
  </TrStyledTableHeadRow>
);

export const getTableBodyCol = (col, colIndex, removeTableBorder, rowIndex, variant) => {
  const TableBodyCellComponent = col.isHeader ? ThStyledTableBodyHeader : TdStyledTableBodyCell;

  return (
    <TableBodyCellComponent
      {...(col.className && { className: col.className })}
      {...(col.colSpan && { colSpan: col.colSpan })}
      headers={col.headers}
      {...(col.id && { id: col.id })}
      key={`body-row${rowIndex + 1}-col${colIndex + 1}`}
      removeTableBorder={removeTableBorder}
      {...(col.rowSpan && { rowSpan: col.rowSpan })}
      {...getRowScope(col.rowScope)}
      variant={variant}>
      {col.children}
    </TableBodyCellComponent>
  );
};

export const getTableBodyRow = (displayStripedRows, removeTableBorder, row, rowIndex, variant) => (
  <TrStyledTableBodyRow
    displayStripedRows={displayStripedRows}
    key={`body-row${rowIndex + 1}`}
    rowIndex={rowIndex}>
    {row.cols?.map((col, colIndex) => getTableBodyCol(col, colIndex, removeTableBorder, rowIndex, variant))}
  </TrStyledTableBodyRow>
);

export const getTableFootCol = (col, colIndex, removeTableBorder, rowIndex, variant) => {
  const TableFootCellComponent = col.isHeader ? ThStyledTableFootHeader : TdStyledTableFootCell;

  return (
    <TableFootCellComponent
      {...(col.className && { className: col.className })}
      {...(col.colSpan && { colSpan: col.colSpan })}
      {...(col.id && { id: col.id })}
      key={`foot-row${rowIndex + 1}-col${colIndex + 1}`}
      removeTableBorder={removeTableBorder}
      {...(col.rowSpan && { rowSpan: col.rowSpan })}
      {...getRowScope(col.rowScope)}
      variant={variant}>
      {col.children}
    </TableFootCellComponent>
  );
};

// component
const TableComponent = ({ className, removeTableBorder, tableData, variant }) => (
  <TableStyled
    className={classnames('nmx-table', className)}
    {...(tableData.summary && { summary: tableData.summary })}>
    {tableData.caption && tableData.caption.children && (
      <caption className='nmx-table__caption'>
        {tableData.caption.children}
      </caption>
    )}
    {/** Table Head is an optional table section, check if config exists */}
    {tableData.tableHead && (
      <thead className='nmx-table__head'>
        {tableData.tableHead?.rows?.map((row, rowIndex) => (
          getTableHeadRow(removeTableBorder, row, rowIndex, variant)
        ))}
      </thead>
    )}
    {/** Table Body *should be* a required table section (not wrapped in a conditional statement) */}
    <tbody className='nmx-table__body'>
      {tableData.tableBody?.rows?.map((row, rowIndex) => (
        getTableBodyRow(tableData.tableBody.displayStripedRows, removeTableBorder, row, rowIndex, variant)
      ))}
    </tbody>
    {/** Table Foot is an optional table section, check if config exists */}
    {tableData.tableFoot && (
      <tfoot className='nmx-table__foot'>
        {tableData.tableFoot?.rows?.map((row, rowIndex) => (
          <tr key={`foot-row${rowIndex + 1}`}>
            {row.cols?.map((col, colIndex) => getTableFootCol(col, colIndex, removeTableBorder, rowIndex, variant))}
          </tr>
        ))}
      </tfoot>
    )}
  </TableStyled>
);

const commonColPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  colSpan: PropTypes.number,
  /** used for robust accessibility: Use id and headers attributes to associate data cells with header cells in data tables */
  id: PropTypes.string,
};

TableComponent.propTypes = {
  /** Optional className added to base Table Component */
  className: PropTypes.string,
  /** Optional prop to add stripes to tableBody row content */
  removeTableBorder: PropTypes.bool,
  /** Required tableData config object containing necessary data to construct Table */
  tableData: PropTypes.shape({
    caption: PropTypes.shape({
      children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
      ]),
    }),
    summary: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
    ]),
    tableHead: PropTypes.shape({
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          cols: PropTypes.arrayOf(
            PropTypes.shape({
              ...commonColPropTypes,
              colScope: PropTypes.oneOf(['col', 'colgroup']),
              isHeader: PropTypes.bool,
              /** width calculated to rems if provided in pixels (string), or provide a percentage (string) */
              width: PropTypes.string,
            }),
          ),
        }),
      ),
    }),
    tableBody: PropTypes.shape({
      displayStripedRows: PropTypes.bool,
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          cols: PropTypes.arrayOf(
            PropTypes.shape({
              ...commonColPropTypes,
              /** used for robust accessibility: Use id and headers attributes to associate data cells with header cells in data tables */
              headers: PropTypes.string,
              isHeader: PropTypes.bool,
              rowScope: PropTypes.oneOf(['row', 'rowgroup']),
              rowSpan: PropTypes.number,
            }),
          ),
        }),
      ),
    }),
    tableFoot: PropTypes.shape({
      rows: PropTypes.arrayOf(
        PropTypes.shape({
          cols: PropTypes.arrayOf(
            PropTypes.shape({
              ...commonColPropTypes,
              isHeader: PropTypes.bool,
              rowSpan: PropTypes.number,
              rowScope: PropTypes.oneOf(['row', 'rowgroup']),
            }),
          ),
        }),
      ),
    }),
  }).isRequired,
  /** Optional variant prop allowing the user to serve an Aura-styled Table */
  variant: PropTypes.oneOf(['generic', 'branded']),
};

TableComponent.defaultProps = {
  removeTableBorder: false,
  tableData: {
    tableBody: { displayStripedRows: false },
    tableHead: { rows: [{ cols: [{ isHeader: true }] }] },
  },
  variant: 'generic',
};

export default TableComponent;

