import Location from '@nmx/utils-frontend/dist/utilities/frontend/location';
import Session from '@nmx/utils-frontend/dist/utilities/frontend/session';

if (Location.hasQueryParam('utm_source')) {
  Session.set('utmSource', Location.getQueryParam('utm_source'));
}

if (Location.hasQueryParam('utm_medium')) {
  Session.set('utmMedium', Location.getQueryParam('utm_medium'));
}

if (Location.hasQueryParam('utm_campaign')) {
  Session.set('utmCampaign', Location.getQueryParam('utm_campaign'));
}

if (Location.hasQueryParam('utm_content')) {
  Session.set('utmContent', Location.getQueryParam('utm_content'));
}

if (Location.hasQueryParam('utm_term')) {
  Session.set('utmTerm', Location.getQueryParam('utm_term'));
}

if (Location.hasQueryParam('agentid')) {
  // try to get agentid from query params
  const agentIdFromQueryParam = Location.getQueryParam('agentid');

  if (agentIdFromQueryParam) {
    // Set agentid to SessionStorage if present as a query param
    Session.set('agentNumber', agentIdFromQueryParam);
  }
}
