import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
// template components
import AssistiveText from '../../components/AssistiveText';
import { Col } from '../../foundations/Grid';
import Link from '../../components/Link';
// styles
import {
  ColStyled,
  ContainerStyled,
  DivStyledBgImageContainer,
  DivStyledContainer,
  DivStyledCtaContainer,
  DivStyledImageContainer,
  LinkStyledOverlay,
  PictureStyled,
  RowStyled,
  SectionStyled,
  Styles,
  TypographyStyledDescription,
  TypographyStyledTitle,
} from './styles';

export const ExperienceCalloutComponent = React.forwardRef(({
  actionId,
  ariaLabel,
  assistiveText,
  backgroundVariant,
  branding,
  buttonText,
  calloutBackgroundVariant,
  calloutThemeType,
  className,
  descriptionText,
  disableBottomPadding,
  disableLazyLoad,
  headingText,
  illustration,
  imageAltText,
  imageUrl,
  isFullWidth,
  isInternalPdf,
  isOutgoingLink,
  linkUrl,
  moduleName,
  sectionId,
  themeType,
  withBGImage,
  withHoverShadow,
}, ref) => {
  const styles = Styles();
  const IllustrationToRender = illustration;
  return (
    <SectionStyled
      id={sectionId}
      ref={ref}
      className={className}
      backgroundVariant={backgroundVariant}
      branding={branding}
      data-module={`${sectionId}:${moduleName}:A`}
      disableBottomPadding={disableBottomPadding}
      hasFullWidthContainer={isFullWidth}
      themeType={themeType}>
      <ContainerStyled
        backgroundVariant={calloutBackgroundVariant}
        isFullWidthContainer={isFullWidth}
        withBGImage={withBGImage}
        branding={branding}>
        <DivStyledContainer
          backgroundVariant={calloutBackgroundVariant}
          branding={branding}
          isFullWidthContainer={isFullWidth}
          themeType={calloutThemeType}
          withHoverShadow={withHoverShadow}>
          <RowStyled align="center">
            <ColStyled medium={5}>
              {/* Background image */}
              {withBGImage ? (
                <DivStyledBgImageContainer
                  className='nmx-experience-callout-image-container lazy'
                  data-bg={`url(${imageUrl})`} /* If a non URL imageUrl is passed in, how should we handle setting the data-bg? */
                  disableLazyLoad={disableLazyLoad}
                  forFullWidthContainer={isFullWidth}
                  imageUrl={imageUrl}
                />
              ) : (
                <DivStyledImageContainer
                  forFullWidthContainer={isFullWidth}>
                  {illustration
                    ? (
                      <IllustrationToRender style={styles.IllustrationExperienceCalloutStyled} />
                    )
                    : (
                      <PictureStyled
                        src={imageUrl}
                        altText={imageAltText}
                      />
                    )}
                </DivStyledImageContainer>
              )}
            </ColStyled>
            <Col
              align='center'
              medium={7}>
              <DivStyledCtaContainer withBGImage={withBGImage}>
                <TypographyStyledTitle
                  component='h2'
                  hasCopy={descriptionText}
                  disableBottomPadding
                  themeType={calloutThemeType}>
                  {headingText}
                </TypographyStyledTitle>
                {descriptionText && (
                  <TypographyStyledDescription
                    disableBottomPadding
                    themeType={calloutThemeType}>
                    {descriptionText}
                  </TypographyStyledDescription>
                )}
                <Link
                  id={`${sectionId}-button`}
                  {...!isFullWidth && { className: 'button-experience-callout-styled' }}
                  ariaLabel={ariaLabel}
                  backgroundVariant={calloutBackgroundVariant}
                  isInternalPdf={isInternalPdf}
                  isOutgoingLink={isOutgoingLink}
                  linkUrl={linkUrl}
                  themeType={calloutThemeType}
                  variant='buttonLinkSecondary'>
                  {buttonText}
                </Link>
              </DivStyledCtaContainer>
            </Col>
            {/* Clickable overlay, with drop shadow, for the in-page variation */}
            {!isFullWidth && (
              <LinkStyledOverlay
                id={`${sectionId}-button`}
                ariaLabel={assistiveText}
                data-tracking-id={actionId}
                isInternalPdf={isInternalPdf}
                isOutgoingLink={isOutgoingLink}
                linkUrl={linkUrl}
                themeType={calloutThemeType}
                variant='buttonLinkSecondary'>
                <AssistiveText>
                  {assistiveText}
                </AssistiveText>
              </LinkStyledOverlay>
            )}
          </RowStyled>
        </DivStyledContainer>
      </ContainerStyled>
    </SectionStyled>
  );
});

ExperienceCalloutComponent.propTypes = {
  /** optional id that goes on overlay CTA */
  actionId: PropTypes.string,
  /** optional Assistive Text for CTA Button that is part of the card style of callout */ // TODO: this needs some renaming/refactoring work
  assistiveText: PropTypes.string,
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** CTA button text */
  buttonText: PropTypes.string.isRequired,
  /** backgroundVariant of callout to be different background color based on themeType */
  calloutBackgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** callout theme, which determines color styles */
  calloutThemeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** optional additional className */
  className: PropTypes.string,
  /** Callout Description Text */
  descriptionText: PropTypes.string,
  /** optional disable bottom section padding (margin) */
  disableBottomPadding: PropTypes.bool,
  /** optional boolean to disable lazyloading of image */
  disableLazyLoad: PropTypes.bool,
  /** illustration object, illustrations found in foundations */
  illustration: PropTypes.any, // TODO: use Illustration or Image interchangeably - then use BG or regular (grid card) as variants?
  /** image url path */
  imageUrl: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Image Alt text for non-BG images */
  imageAltText: PropTypes.string,
  /** boolean to render callout full-width (bleeds over grid boundaries to viewport width on large screens) */
  isFullWidth: PropTypes.bool,
  /** optional boolean to automatically add outgoing link attributes: target="_blank" rel="noopener nofollow" */
  isInternalPdf: PropTypes.bool,
  /** optional boolean to automatically add outgoing link attributes: target="_blank" rel="noopener nofollow noreferrer" */
  isOutgoingLink: PropTypes.bool,
  /** heading text of callout */
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** URL of link */
  linkUrl: PropTypes.string.isRequired,
  /** Layout where image is a fluid background image (left-aligned in full-width section) or a block element properly sitting in Col (Grid) */
  withBGImage: PropTypes.bool,
  /** Style to designate card */
  withHoverShadow: PropTypes.bool,
  /**  Used to populate and define a module on the section for a given page, used in adobe. (Ex: hero, intro-callout, content-hub) */
  moduleName: PropTypes.string,
  /** Id for the section */
  sectionId: PropTypes.string.isRequired,
  /** section theme, which determines color styles */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme']),
  /** variant to designate which style of Callout to render (FullWidth, Floating Card, etc) */
  variant: PropTypes.oneOf(['withBgImage']), // TODO: fullWidth, cardStyle, etc?
};

ExperienceCalloutComponent.defaultProps = {
  backgroundVariant: 'lightA',
  branding: 'nm',
  buttonText: 'Get Started',
  calloutBackgroundVariant: 'lightB',
  calloutThemeType: 'lightTheme',
  disableBottomPadding: false,
  disableLazyLoad: false,
  isFullWidth: true,
  linkUrl: 'https://northwesternmutual.com/',
  themeType: 'lightTheme',
  withBGImage: true,
  withHoverShadow: false,
};

export default withTheme(ExperienceCalloutComponent);
