import xhr from 'xhr';
import Session from '@nmx/utils-frontend/dist/utilities/frontend/session';

const setFlags = (dynamicConfig) => {
  const dynamicConfigCopy = { ...dynamicConfig };
  if (dynamicConfigCopy.banner) {
    const linkUrl = (dynamicConfigCopy.banner.data.ctaUrl
      || dynamicConfigCopy.banner.data.buttonCtaUrl
      || dynamicConfigCopy.banner.data.drawerContents.linkUrl);

    if (linkUrl && linkUrl.replace(/\/+$/, '') === window.location.pathname.replace(/\/+$/, '')) {
      dynamicConfigCopy.banner.isActive = false;
      return dynamicConfigCopy;
    }
  }

  return dynamicConfigCopy;
};

export default function GetDynamicConfig(configUrl, sessionKey) {
  return new Promise((resolve, reject) => {
    const dynamicConfigStoredInSession = Session.get(sessionKey);
    if (dynamicConfigStoredInSession) {
      return resolve(setFlags(JSON.parse(atob(dynamicConfigStoredInSession))));
    }
    return xhr({
      method: 'get',
      uri: configUrl,
    }, (err, resp, body) => {
      if (err) {
        return reject(err);
      }
      if (resp.statusCode !== 200) {
        const statusCodeError = `Getting ${sessionKey} resulted in status code: ${resp.statusCode}`;
        return reject(statusCodeError);
      }
      Session.set(sessionKey, body);
      return resolve(setFlags(JSON.parse(atob(body))));
    });
  });
}
