import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// styles
import {
  ColStyled,
  ContainerStyled,
  DivStyledBackgroundContainer,
  DivStyledHeadingContainer,
  RowStyled,
  SectionStyled,
  TypographyStyled,
} from './styles';

export const FlexibleHeroComponent = React.forwardRef(({
  backgroundImageAriaLabel,
  backgroundVariant,
  branding,
  className,
  desktopImage,
  desktopImageJpg,
  desktopImageLowRes,
  desktopImageWebp,
  headingText,
  headingTextPosition,
  mobileImage,
  mobileImageJpg,
  mobileImageLowRes,
  mobileImageWebp,
  sectionId,
  themeType,
}, ref) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // headingText positioning configuration. Center is default.
  let mediumColOffset = 3;
  if (headingTextPosition === 'left') {
    mediumColOffset = 0;
  }

  useEffect(() => {
    setImageLoaded(true);
  }, [desktopImageJpg, mobileImageJpg]);

  return (
    <SectionStyled
      id={`section-${sectionId}`}
      className={className}
      data-module={`section-${sectionId}:flexible-hero-module:A`}
      ref={ref}
      role='banner'>
      <DivStyledBackgroundContainer
        id={`background-image-${sectionId}`}
        imageLoaded={imageLoaded}
        desktopImage={desktopImage}
        desktopImageLowRes={desktopImageLowRes}
        desktopImageJpg={desktopImageJpg} // deprecated
        desktopImageWebp={desktopImageWebp} // deprecated
        mobileImage={mobileImage}
        mobileImageLowRes={mobileImageLowRes}
        mobileImageJpg={mobileImageJpg} // deprecated
        mobileImageWebp={mobileImageWebp} // deprecated
      >
        {backgroundImageAriaLabel && (
          <span
            role='img'
            aria-label={backgroundImageAriaLabel} />
        )}
      </DivStyledBackgroundContainer>
      <ContainerStyled>
        <RowStyled headingTextPosition={headingTextPosition}>
          <ColStyled
            medium={6}
            mediumOffset={mediumColOffset}>
            <DivStyledHeadingContainer
              backgroundVariant={backgroundVariant}
              branding={branding}
              headingTextPosition={headingTextPosition}
              themeType={themeType}>
              <TypographyStyled
                id={`flexible-hero-heading-${sectionId}`}
                component='h1'
                headingTextPosition={headingTextPosition}
                themeType={themeType}>
                {headingText}
              </TypographyStyled>
            </DivStyledHeadingContainer>
          </ColStyled>
        </RowStyled>
      </ContainerStyled>
    </SectionStyled>
  );
});

FlexibleHeroComponent.propTypes = {
  /** ariaLabel for background image */
  backgroundImageAriaLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** backgroundVariant allows section to be different background color based on themeType */
  backgroundVariant: PropTypes.oneOf(['darkDefault', 'lightA', 'lightB', 'lightC']),
  /** sets overall branding of component or module, default='nm' */
  branding: PropTypes.oneOf(['nm', 'pcg']),
  /** base64 encoded desktop 'fuzzy' image for initial display before full image renders */
  desktopImageLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** Will be required in 4.x.x: href or src of desktop image to load */
  desktopImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Will be DEPRECATED in 4.x.x: href or src of desktop image to load */
  desktopImageJpg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** will be DEPRECATED in 4.x.x: Webp version of backgroundImage desktop */
  desktopImageWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** Will be required in 4.x.x: href or src of mobile image to load */
  mobileImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** base64 encoded mobile 'fuzzy' image for initial display before full image renders */
  mobileImageLowRes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** will be DEPRECATED in 4.x.x: href or src of mobile image to load */
  mobileImageJpg: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** will be DEPRECATED in 4.x.x: Webp version of backgroundImage Mobile */
  mobileImageWebp: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  /** optional additional className */
  className: PropTypes.string,
  /** Required heading text of the Module */
  headingText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  /** headingTextPosition */
  headingTextPosition: PropTypes.oneOf(['left', 'center', 'bottom']),
  /** required section id */
  sectionId: PropTypes.string.isRequired,
  /** Optional themeType */
  themeType: PropTypes.oneOf(['lightTheme', 'darkTheme', 'nmx-pcg']),
};

FlexibleHeroComponent.defaultProps = {
  backgroundVariant: 'lightB',
  headingTextPosition: 'left',
  themeType: 'lightTheme',
};

export default FlexibleHeroComponent;
