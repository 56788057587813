import styled, { css } from 'styled-components';
import rem from 'polished/lib/helpers/rem';

export const getTableCellPadding = (theme, variant) => {
  const mediumUp = theme.mediumUp();
  let padding = mediumUp ? `${rem(8)} ${rem(4)} ${rem(8)} ${rem(12)}` : `${rem(8)} ${rem(4)} ${rem(8)} ${rem(8)}`; // default generic
  if (variant === 'branded') {
    padding = mediumUp ? `${rem(16)} ${rem(16)} ${rem(16)} ${rem(32)}` : `${rem(16)} ${rem(4)} ${rem(16)} ${rem(8)}`; // branded
  }
  return padding;
};

// table styles
export const TableStyled = styled.table`
  ${({ theme }) => css`
    width: 100%;
    border: ${rem(1)} solid ${theme.colors.stroke.lightTheme.tableBorder};
    border-collapse: collapse;
    background-color: ${theme.colors.background.lightTheme.lightA};
    caption {
      display: none;
    }
  `}
`;

// head styles
export const TrStyledTableHeadRow = styled.tr`
  ${({ theme, variant }) => css`
    background-color: ${variant === 'branded' ? theme.colors.background.darkTheme.darkDefault : theme.colors.background.lightTheme.lightA};
  `}
`;

/* $width intentionally set as a transient prop to not add "width" attribute to DOM element: https://styled-components.com/docs/api#transient-props */
export const defaultHeadCellStyles = (removeTableBorder, theme, variant, $width) => {
  const thisWidth = $width && ($width.includes('px') ? rem($width) : $width);
  return css`
    ${thisWidth && `width: ${thisWidth}`};
    padding: ${getTableCellPadding(theme, variant)};
    font-size: ${theme.mediumUp ? rem(21) : rem(17)};
    line-height: ${theme.mediumUp ? rem(28) : rem(24)};
    font-weight: 500;
    color: ${variant === 'branded' ? theme.colors.text.darkTheme.default : theme.colors.text.lightTheme.default};
    ${!removeTableBorder && css`
      border: ${rem(1)} solid ${theme.colors.stroke.lightTheme.tableBorder};
    `}
  `;
};

export const ThStyledTableHeadHeader = styled.th`
  ${({ removeTableBorder, theme, variant, $width }) => defaultHeadCellStyles(removeTableBorder, theme, variant, $width)};
`;

export const TdStyledTableHeadCell = styled.td`
  ${({ removeTableBorder, theme, variant, $width }) => defaultHeadCellStyles(removeTableBorder, theme, variant, $width)};
`;

// body styles
export const TrStyledTableBodyRow = styled.tr`
  ${({ displayStripedRows, rowIndex, theme }) => css`
    ${displayStripedRows && rowIndex % 2 !== 0
      && css`
        background-color: ${theme.colors.background.lightTheme.lightB};
    `}
  `}
`;

export const defaultBodyCellStyles = (removeTableBorder, theme, variant) => css`
  padding: ${getTableCellPadding(theme, variant)};
  font-size: ${theme.mediumUp ? rem(18) : rem(15)};
  line-height: ${theme.mediumUp ? rem(28) : rem(24)};
  font-weight: 400;
  ${!removeTableBorder
    && css`
      border: ${rem(1)} solid ${theme.colors.stroke.lightTheme.tableBorder};
  `}
`;

export const ThStyledTableBodyHeader = styled.th`
  ${({ removeTableBorder, theme, variant }) => css`
    ${defaultBodyCellStyles(removeTableBorder, theme, variant)};
    ${variant === 'branded' && css`
      background-color: ${theme.colors.background.lightTheme.lightB};
    `}
  `
}`;

export const TdStyledTableBodyCell = styled.td`
  ${({ removeTableBorder, theme, variant }) => css`
    ${defaultBodyCellStyles(removeTableBorder, theme, variant)};
  `
}`;

// footer styles
export const defaultFooterCellStyles = (removeTableBorder, theme, variant) => css`
  ${defaultBodyCellStyles(removeTableBorder, theme, variant)};
   /** add/adjust footer-cell-specific styles here */
`;

export const ThStyledTableFootHeader = styled.th`
  ${({ removeTableBorder, theme, variant }) => css`
    ${defaultFooterCellStyles(removeTableBorder, theme, variant)};
    background-color: ${variant === 'branded' ? theme.colors.background.lightTheme.lightB : theme.colors.background.lightTheme.lightA};
  `
}`;

export const TdStyledTableFootCell = styled.td`
  ${({ removeTableBorder, theme, variant }) => css`
    ${defaultFooterCellStyles(removeTableBorder, theme, variant)};
  `
}`;
