import React from 'react';
import PropTypes from 'prop-types';
// styles
import { TextFieldStyled } from '../../styles';

export const GenericFormInputComponent = React.forwardRef(({
  hasError,
  helperText,
  onBlur,
  onChange,
  onClick,
  placeholderText,
  themeType,
  thisId,
  thisLabelText,
  thisMaxLength,
  thisName,
  value,
}, ref) => (
  <TextFieldStyled
    id={thisId}
    ref={ref}
    // configs
    helperText={helperText}
    labelText={thisLabelText}
    maxLength={thisMaxLength}
    name={thisName}
    placeholderText={placeholderText}
    required
    themeType={themeType}
    value={value}
    // handlers
    hasError={hasError}
    onBlur={onBlur}
    onChange={onChange}
    onClick={onClick}
  />
));

export const FirstNameInput = React.forwardRef((props, ref) => (
  <GenericFormInputComponent
    {...props}
    ref={ref}
    thisId='suspect-email-capture-form-first-name-input'
    thisName='firstName'
    thisLabelText='First name'
    thisMaxLength={20}
  />
));

export const LastNameInput = React.forwardRef((props, ref) => (
  <GenericFormInputComponent
    {...props}
    ref={ref}
    thisId='suspect-email-capture-form-last-name-input'
    thisName='lastName'
    thisLabelText='Last name'
    thisMaxLength={30}
  />
));

export const EmailInput = React.forwardRef((props, ref) => (
  <GenericFormInputComponent
    {...props}
    ref={ref}
    thisId='suspect-email-capture-form-email-input'
    thisName='email'
    thisLabelText='Email'
    thisMaxLength={200}
  />
));

GenericFormInputComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};

export default GenericFormInputComponent;
